<template>
  <v-dialog
    v-model="carteVisite"
    :persistent="$vuetify.breakpoint.smAndDown"
    scrollable
    max-width="750px"
    content-class="force-100-height"
    transition="dialog-bottom-transition"
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <template
      v-slot:activator="{ on, attrs }"
    >
      <div class="px-3 mb-3">
        <a
          class="black--text hover-underline"
          v-bind="attrs"
          v-on="on"
        >{{ $t('connaitre-conseiller',{conseiller: contact.prenom}) }}
          <v-icon
            color="black"
            dense
          >
            mdi-chevron-right
          </v-icon></a>
      </div>
    </template>
    <v-card>
      <v-card-title
        class="dialog-card-color pa-3 white--text flex-nowrap align-start"
      >
        {{ $t('connaitre-conseiller',{conseiller: contact.prenom}) }}
        <v-spacer />
        <v-btn
          icon
          dark
          @click="carteVisite = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text
        class="pa-0 card-text-cv"
        style="height: 100%;"
      >
        <v-card
          :max-width="500"
          class="mx-auto my-10 pb-2"
        >
          <v-list-item class="flex-column contact">
            <v-list-item-avatar
              v-if="statut != 'commande' && statut != 'facture'"
              size="80"
              color="#F0F0F0"
            >
              <v-img
                :src="contact.photo_url"
              />
            </v-list-item-avatar>
            <v-list-item-content class="text-center">
              <v-list-item-title
                v-if="!showSav"
                class="text-h5 align-center d-flex justify-center"
              >
                {{ contact.prenom }} {{ contact.nom_famille }}
                <v-icon
                  color="black"
                  class="ml-2"
                >
                  mdi-checkbox-marked-circle
                </v-icon>
              </v-list-item-title>
              <v-list-item-title v-else>
                {{ fonction }}
              </v-list-item-title>
              <v-list-item-subtitle v-if="!showSav">
                {{ fonction }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <v-icon left>
                  mdi-phone
                </v-icon>
                <a
                  v-if="tel == 'telephone'"
                  class="black--text"
                  :href="`tel:${contact.telephone}`"
                >{{ contact.telephone }}</a>
                <a
                  v-else
                  class="black--text"
                  :href="`tel:${contact.tel_portable}`"
                >{{ contact.tel_portable }}</a>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <v-icon left>
                  mdi-email
                </v-icon> <a
                  class="black--text"
                  :href="`mailto:${contact.email}`"
                >{{ contact.email }}</a>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <Rdvs
            v-if="!fromAnnuaire"
            :client="client"
            :prise-rdv-possible="priseRdvPossible"
            :is-petite-source="isPetiteSource"
            :contact="contact"
            :show-btn-signature="showBtnSignature"
          />
          <DemandeDeRappel
            v-if="!fromAnnuaire"
            :show-btn-demande-de-rappel="showBtnDemandeDeRappel"
            :show-btn-signature="showBtnSignature"
            :client="client"
            :contact="contact"
          />
        </v-card>
        <v-card
          :max-width="500"
          class="mx-auto mb-10 px-3"
        >
          <div
            v-if="contact.custom_field_array && contact.custom_field_array.anciennete"
            class="bottom-bordure py-3"
          >
            <h3
              class="text-body-1"
            >
              {{ $t('anciennete') }}
            </h3>
            <p
              class="text-h6 mb-0"
            >
              {{ contact.custom_field_array.anciennete }} {{ $t('ans') }}
            </p>
          </div>
          <div
            v-if="contact.custom_field_array && contact.custom_field_array.nb_bassin"
            class="bottom-bordure py-3"
          >
            <h3
              class="text-body-1"
            >
              {{ $t('nb-piscines-vendues') }}
            </h3>
            <p
              class="text-h6 mb-0"
            >
              {{ contact.custom_field_array.nb_bassin }} {{ $t('piscines') }}
            </p>
          </div>
          <div
            v-if="contact.custom_field_array && contact.custom_field_array.nb_kit"
            class="bottom-bordure py-3"
          >
            <h3
              class="text-body-1"
            >
              {{ $t('nb-kit-vendus') }}
            </h3>
            <p
              class="text-h6 mb-0"
            >
              {{ contact.custom_field_array.nb_kit }} {{ $t('kits') }}
            </p>
          </div>
          <div
            v-if="contact.custom_field_array && contact.custom_field_array.nb_pap"
            class="bottom-bordure py-3"
          >
            <h3
              class="text-body-1"
            >
              {{ $t('nb-pap-vendues') }}
            </h3>
            <p
              class="text-h6 mb-0"
            >
              {{ contact.custom_field_array.nb_pap }} {{ $t('pap') }}
            </p>
          </div>
          <div
            v-if="contact.custom_field_array && contact.custom_field_array.terrasse"
            class="bottom-bordure py-3"
          >
            <h3
              class="text-body-1"
            >
              {{ $t('nb-terrasses-vendues') }}
            </h3>
            <p
              class="text-h6 mb-0"
            >
              {{ contact.custom_field_array.terrasse }} {{ $t('terrasses') }}
            </p>
          </div>
          <p
            v-if="contact.custom_field_array && contact.custom_field_array.biographie"
            class="py-3"
            :inner-html.prop="contact.custom_field_array.biographie"
          />
        </v-card>
        <v-card
          v-if="contact.custom_field_array && contact.custom_field_array.avis"
          :max-width="500"
          class="mx-auto mb-10 pa-5"
        >
          <h3
            class="text-h6 mb-3"
          >
            {{ $t('avis-client') }}
          </h3>
          <p>
            <a
              href="https://www.avis-verifies.com/avis-clients/piscinelle.com"
              target="_blank"
            >
              <v-img
                class="mx-auto my-2"
                src="https://cl.avis-verifies.com/fr/cache/8/9/2/892393db-103f-56b4-51f1-c320e0fe92ec/widget3/widget03-32201.png"
                alt="Avis clients piscinelle.com"
                width="200"
              />
            </a>
          </p>
          <CarouselITem
            :items="contact.custom_field_array.avis"
            type="avis"
          />
        </v-card>

        <v-card
          v-if="contact.custom_field_array && contact.custom_field_array.realisations"
          :max-width="500"
          class="mx-auto mb-10 pa-3"
        >
          <h3
            class="text-h6 mb-3"
          >
            {{ $t('realisations') }}
          </h3>
          <CarouselITem
            :items="contact.custom_field_array.realisations"
            type="realisations"
          />
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import CarouselITem from '@/components/CarouselItem'
import DemandeDeRappel from '@/components/contact/DemandeDeRappel'
import Rdvs from '@/components/contact/Rdvs'

import { bus } from '@/main'

export default
{
  name: 'CarteVisite',
  components: {
    CarouselITem,
    Rdvs,
    DemandeDeRappel
  },
  props:
  {
    contact:
    {
      type: Object,
      default: () => {}
    },
    client:
    {
      type: Object,
      default: () => {}
    },
    isPetiteSource: {
      type: Boolean,
      default: false
    },
    statut:
    {
      type: String,
      default: ''
    },
    showSav: {
      type: Boolean,
      default: false
    },
    priseRdvPossible: {
      type: Boolean,
      default: false
    },
    fromAnnuaire: {
      type: Boolean,
      default: false
    },
    showBtnDemandeDeRappel:
    {
      type: Boolean,
      default: false
    },
    showBtnSignature:
    {
      type: Boolean,
      default: false
    },
    tel: {
      type: String,
      default: 'tel_portable'
    },
    fonction:
    {
      type: String,
      default: function () {
        return this.$t('service-client-piscinelle')
      }
    }
  },
  data () {
    return {
      carteVisite: false
    }
  },
  created () {
    if (this.fromAnnuaire) this.carteVisite = true
    bus.$on('close_cv', (data) => {
      this.carteVisite = data
    })
  },
  beforeDestroy () {
    bus.$off('close_cv')
  }
}
</script>
