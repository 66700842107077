<template>
  <v-dialog
    v-model="dialogDemandeRappel"
    max-width="400"
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="text-center px-3">
        <v-btn
          v-if="showBtnDemandeDeRappel"
          class="w-100"
          :class="showBtnSignature ? '' : (client.is_petite_source ? 'background-ps-primary primary-ps-hover' : 'background-primary primary-hover')"
          :dark="!showBtnSignature"
          v-bind="attrs"
          v-on="on"
        >
          {{ $t('demande-de-rappel') }}
        </v-btn>
      </div>
    </template>
    <v-card class="py-4">
      <v-card-text class="pb-1">
        <p>{{ $t('dialog-demande-de-rappel') }}</p>
        <v-text-field
          v-model="client.tel1"
          outlined
          :rules="phoneRules"
          :label="$t('votre-telephone')"
          required
        />
      </v-card-text>
      <v-card-actions class="flex-wrap justify-center">
        <v-btn
          :class="client.is_petite_source ? 'background-ps-primary primary-ps-hover' : 'background-primary primary-hover'"
          class="white--text mt-2"
          :loading="loading"
          @click="demandeRappel(contact.email)"
        >
          {{ $t('notifier-conseiller') }}
        </v-btn>
        <v-btn
          class="mt-2"
          @click="dialogDemandeRappel = false"
        >
          {{ $t('annuler') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { bus } from '@/main'

export default
{
  name: 'DemandeDeRappel',
  props:
  {
    contact:
    {
      type: Object,
      default: () => {}
    },
    client:
    {
      type: Object,
      default: () => {}
    },
    showBtnDemandeDeRappel:
    {
      type: Boolean,
      default: false
    },
    showBtnSignature:
    {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      dialogDemandeRappel: false,
      phoneRules: [
        value => !!value || this.$t('champ-requis'),
        value => {
          const pattern = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/
          return pattern.test(value) || this.$t('telephone-valide')
        }
      ]
    }
  },
  methods: {
    demandeRappel (commercialEmail) {
      this.loading = true
      this.$store.dispatch('demandeRappel', commercialEmail).then(() => {
        this.loading = false
        this.dialogDemandeRappel = false // Fermeture du dialog
        bus.$emit('close_mobile_dialog', false)
        bus.$emit('close_cv', false)
      })
    }
  }
}
</script>
