<template>
  <div>
    <div
      v-if="client.rdvs"
      class="px-3"
    >
      <v-alert
        v-for="(rdv, index) in client.rdvs"
        :key="index"
        type="info"
      >
        <span>
          {{ $t('rdv.title', {type: $t('rdv.type.'+rdv.type), date: $d(new Date(rdv.date_prevue.replace(/-/g, "/")), 'long')}) }}
        </span>
      </v-alert>
    </div>
    <v-dialog
      v-if="priseRdvPossible && !client.rdvs && contact.rdv_auto !== 'aucun' && !showBtnSignature"
      v-model="dialog"
      scrollable
      max-width="750px"
      transition="dialog-bottom-transition"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <template
        v-slot:activator="{ on, attrs }"
      >
        <div class="text-center px-3 mb-3">
          <v-btn
            class="w-100"
            v-bind="attrs"
            v-on="on"
          >
            <span>{{ $t('prendre-rdv') }}</span>
          </v-btn>
        </div>
      </template>
      <v-card class="contenu-piscinelle">
        <v-card-title
          class="dialog-card-color pa-3 white--text flex-nowrap align-start"
        >
          {{ $t('prendre-rdv') }}
          <v-spacer />
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text
          class="pa-4"
          style="height: 100%"
        >
          <v-form>
            <v-container
              v-if="contact.creneaux"
              fluid
              class="px-0"
            >
              <v-row class="text-left">
                <v-col
                  cols="12"
                  sm="8"
                  md="6"
                >
                  <v-select
                    v-model="types[0]"
                    :items="types"
                    item-text="label"
                    item-value="value"
                    :label="$t('type')"
                    required
                    @change="rdvType"
                  />
                </v-col>
              </v-row>
            </v-container>
            <h2 class="mx-0">
              {{ $t('creneaux') }}
            </h2>
            <v-alert
              v-if="!contact.creneaux"
              type="info"
            >
              {{ $t('aucun-creneau') }}
            </v-alert>
            <v-simple-table
              v-if="contact.creneaux"
              id="planning-rdv"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th />
                    <th>
                      {{ $t('9H') }}
                    </th>
                    <th>
                      {{ $t('11H') }}
                    </th>
                    <th>
                      {{ $t('14H') }}
                    </th>
                    <th>
                      {{ $t('17H') }}
                    </th>
                    <th>
                      {{ $t('19H') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(creneau, index) in contact.creneaux"
                    :key="index"
                  >
                    <th
                      class="libelle"
                      :class="{ 'colorPrimary' : creneau.today}"
                    >
                      <!--{{ $d(new Date(creneau.day.replace(/-/g, "/")), 'short') }}-->
                      {{ creneau.libelle }}
                    </th>
                    <td
                      v-for="(creneauInfo, horaire) in creneau.planning"
                      :key="horaire"
                      class="text-center position-relative px-0"
                      :class="{ 'indispo' : !creneauInfo.dispo}"
                      @click="reservation(creneauInfo.date,creneauInfo.dispo)"
                    >
                      <v-tooltip
                        v-if="creneauInfo.dispo"
                        bottom
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            id="tooltip"
                            class="position-absolute absolute-0"
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <span>{{ $d(new Date(creneau.day.replace(/-/g, "/")), 'short') + ', ' + horaire }}</span>
                      </v-tooltip>
                      <v-icon
                        v-if="selectedDate == creneauInfo.date"
                        color="pink lighten-2"
                      >
                        mdi-checkbox-marked-circle
                      </v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <h2 class="mx-0">
              {{ $t('vos-coordonnees') }}
            </h2>
            <div class="px-4 py-2">
              <p>{{ $t('nom-complet') + " : " + client.prenom + " " + client.nom }}</p>
              <p>{{ $t('adresse1') + " : " + client.adresse }}</p>
              <p v-if="client.adresse2">
                {{ $t('adresse2') + " : " + client.adresse2 }}
              </p>
              <p>{{ $t('adresse-complete') + " : " + client.cp + " " + client.ville + " " + client.pays }}</p>
              <p>{{ $t('telephone') + " : " + client.tel1 }}</p>
              <v-textarea
                v-model="remarque"
                name="remarque"
                :label="$t('remarque-rdv')"
                outlined
              />
            </div>
            <p
              v-if="errorMessage"
              class="text-center my-5 colorPrimary"
            >
              {{ errorMessage }}
            </p>
            <v-card-actions class="my-5">
              <v-btn
                dark
                :loading="loading"
                x-large
                min-width="300px"
                :class="client.is_petite_source ? 'background-ps-primary primary-ps-hover' : 'background-primary primary-hover'"
                class="mx-auto"
                @click="contact.creneaux ? prendreRdv(selectedDate,rdv_type,contact.id,remarque,isPetiteSource) : prendreRdv(null,null,contact.id,remarque,isPetiteSource,true)"
              >
                {{ $t('valider') }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { bus } from '@/main'

export default
{
  name: 'RDVS',
  props:
  {
    contact:
    {
      type: Object,
      default: () => {}
    },
    client:
    {
      type: Object,
      default: () => {}
    },
    showBtnSignature:
    {
      type: Boolean,
      default: false
    },
    isPetiteSource: {
      type: Boolean,
      default: false
    },
    priseRdvPossible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialog: false,
      loading: false,
      rdv_type: 'rdv',
      selectedDate: false,
      errorMessage: '',
      remarque: ''
    }
  },
  computed: {
    types () {
      const types = []

      if ((this.contact.rdv_auto === 'physique' || this.contact.rdv_auto === 'all')) {
        types.push({ label: this.$t('rdv.type.rdv'), value: 'rdv' })
      }
      if (this.contact.rdv_auto === 'tel' || this.contact.rdv_auto === 'all') { types.push({ label: this.$t('rdv.type.rdv-tel'), value: 'rdv-tel' }) }

      return types
    }
  },
  methods: {
    reservation: function (horaire, horaireIsDispo) {
      if (horaireIsDispo) {
        this.selectedDate = horaire
        this.errorMessage = ''
      }
    },
    rdvType (type) {
      this.rdv_type = type
    },
    prendreRdv: function (date, type, idCommercial, remarque, isPetiteSource = false, aucunCreneau = false) {
      this.loading = true
      if (date === false) {
        this.errorMessage = this.$t('select-creneau')
        this.loading = false
      } else {
        var data = {
          date: date,
          type: type,
          isPetiteSource: isPetiteSource,
          idCommercial: idCommercial,
          remarque: remarque || '',
          aucunCreneau: aucunCreneau
        }
        this.$store.dispatch('rdv', data).then(() => {
          this.dialog = false
          this.loading = false
          bus.$emit('close_mobile_dialog', false)
          bus.$emit('close_cv', false)
          window.scrollTo(0, 0)
          this.$store.dispatch('getCommande', this.$route.params.id) // Refresh commande
        })
      }
    }
  }
}
</script>
