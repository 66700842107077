<template>
  <v-carousel
    height="370px"
    show-arrows-on-hover
    hide-delimiter-background
    light
    class="carousel-avis-realisation"
  >
    <v-carousel-item
      v-for="(item, index) in items"
      :key="index"
    >
      <div
        v-if="item.type=='image'"
        :id="type+'-'+index"
        v-fancybox:images
        class="d-flex justify-center carousel-slide"
      >
        <v-img
          class="fill-height cursor-pointer"
          :src="item.url"
          :data-src="item.url"
          :data-caption="item.legende"
          :alt="item.legende"
        >
          <div
            v-if="item.legende"
            class="d-flex fill-height align-end"
          >
            <span
              class="white--text d-block text-center legende w-100 px-2 py-5"
            >{{ item.legende }}</span>
          </div>
        </v-img>
      </div>
      <div
        v-else-if="item.type=='video'"
        class="text-center d-flex flex-column justify-end carousel-slide"
      >
        <video
          class="mx-auto mw100p cursor-pointer fill-height"
          controls="controls"
          preload="metadata"
        >
          <source
            :src="item.url+'#t=0.1'"
            type="video/mp4"
          >
        </video>
        <span
          v-if="item.legende"
          class="white--text d-block text-center legende w-100 px-2 py-5"
        >{{ item.legende }}
        </span>
      </div>
      <div
        v-else
        class="d-flex flex-column justify-center carousel-slide"
      >
        <p

          class="text-body-2 px-0 fill-height d-flex justify-center font-italic align-center mb-0"
        >
          <v-icon
            color="black"
            large
          >
            mdi-format-quote-open
          </v-icon>
          {{ item.texte }}
          <v-icon
            class="ml-1"
            color="black"
            large
          >
            mdi-format-quote-close
          </v-icon>
        </p>
        <span
          v-if="item.date"
          class="white--text text-center legende w-100 px-2 py-5"
        >
          {{ $d(new Date(item.date.replace(/-/g, "/")), 'short') }}
        </span>
      </div>
    </v-carousel-item>
  </v-carousel>
</template>

<script>

export default
{
  name: 'CarouselItem',
  props:
  {
    items: {
      type: [Array, Object],
      default: () => []
    },
    type: {
      type: String,
      default: ''
    }
  }
}
</script>
